<template>
  <div class="form-slider">
    <div id="progressBar"></div>
    <input
      class="form-slider-input"
      type="range"
      min="0"
      max="1"
      :step="steps"
      :value="initValue"
      @input="onChanged"
    />
    <div class="tickmarks">
      <div class="tickmark"></div>
      <div class="tickmark"></div>
      <div class="tickmark"></div>
      <div class="tickmark"></div>
      <div class="tickmark"></div>
      <div class="tickmark"></div>
      <div class="tickmark"></div>
      <div class="tickmark"></div>
      <div class="tickmark"></div>
      <div class="tickmark"></div>
      <div class="tickmark"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: ["initValue", "disabled", "steps"],
  emits: ["changed"],
  methods: {
    onChanged($event) {
      this.$el.firstChild.style.width = $event.target.value * 100 + "%";
      this.$emit("changed", $event.target.value);
    },
    disable() {
      let progressBar = this.$el.children[0];
      let input = this.$el.children[1];
      let tickmarks = this.$el.children[2];

      if (this.disabled) {
        progressBar.classList.add("disabled");
        input.classList.add("disabled");
        input.disabled = true;
        for (const e of tickmarks.children) {
          e.classList.add("disabled");
        }
      } else {
        progressBar.classList.remove("disabled");
        input.classList.remove("disabled");
        input.disabled = false;
        for (const e of tickmarks.children) {
          e.classList.remove("disabled");
        }
      }
    },
  },
  mounted() {
    this.$el.firstChild.style.width = this.initValue * 100 + "%";
    this.disable();
  },
  computed: {
    value: {
      get() {
        return this.initValue ? this.initValue : false;
      },
      set() {},
    },
  },
  watch: {
    disabled() {
      this.disable();
    },
  },
};
</script>

<style lang="scss" scoped>
#progressBar {
  position: absolute;
  background: $primary;
  height: 0.4rem;
  z-index: 2;
  pointer-events: none;
}

.form-slider {
  position: relative;
  display: flex;
  align-items: center;

  input {
    z-index: 1;
    width: 100%;
    height: 0.25rem;
    -webkit-appearance: none;
    background: $secondary;
    outline: none;

    &:hover {
      &::-webkit-slider-thumb {
        width: 1rem;
        height: 1rem;
      }

      &::-moz-range-thumb {
        width: 1rem;
        height: 1rem;
      }
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 0.75rem;
      height: 0.75rem;
      border: none;
      border-radius: 50%;
      background: $primary;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 0.75rem;
      height: 0.75rem;
      border: none;
      border-radius: 50%;
      background: $primary;
      cursor: pointer;
    }
  }
}

.tickmarks {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  pointer-events: none;
}

.tickmark {
  height: 0.4rem;
  width: 0.25rem;
  background: $secondary;
}

input[type="range"].disabled {
  background: $secondary-light;

  &:hover {
    &::-webkit-slider-thumb {
      width: 0.75rem;
      height: 0.75rem;
    }

    &::-moz-range-thumb {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &::-webkit-slider-thumb {
    background: $primary-light;
  }

  &::-moz-range-thumb {
    background: $primary-light;
  }
}

#progressBar.disabled {
  background: $primary-light;
}

.tickmark.disabled {
  background: $secondary-light;
}
</style>
